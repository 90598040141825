<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
              <b-row>
                <b-col lg="10" offset-lg="1" md="12" sm="12">
                  <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                    <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="org_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{$t('org_pro.organization')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="pumpType.org_id"
                          :options="organizationList"
                          id="org_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Pump Type Name (En)" vid="pump_type_name" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="pump_type_name"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{$t('irrigation_config.pump_type_name')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="pump_type_name"
                          v-model="pumpType.pump_type_name"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Pump Type Name (Bn)" vid="pump_type_name_bn" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="pump_type_name_bn"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{$t('irrigation_config.pump_type_name_bn')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="pump_type_name_bn"
                          v-model="pumpType.pump_type_name_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </b-col>
              </b-row>
            </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { pumpTypeStore, pumpTypeUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getPumpTypeData()
      this.pumpType = tmp
    }
    this.getOrganizationlist()
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      pumpType: {
        org_id: 0,
        pump_type_name: '',
        pump_type_name_bn: ''
      },
      organizationList: []
    }
  },
  computed: {
    pumpTypeList: function () {
      return this.$store.state.list
    }
  },
  methods: {
    getPumpTypeData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.pumpType.id) {
        result = await RestApi.putData(irriSchemeServiceBaseUrl, `${pumpTypeUpdate}/${this.id}`, this.pumpType)
      } else {
        result = await RestApi.postData(irriSchemeServiceBaseUrl, pumpTypeStore, this.pumpType)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('IrriConfig/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getOrganizationlist () {
      const orgList = this.$store.state.commonObj.organizationProfileList
      this.organizationList = orgList.map(obj => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    }
  }
}
</script>
